import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../logic/hooks";
import {
    selectCountdownEnd,
    selectCounterState,
    selectImpressionMode,
    selectRoundCount,
    selectTournamentOptions,
    selectTournamentState,
} from "../redux/appSlice";
import ImpressionPairing from "./modes/ImpressionPairing";
import ImpressionRanking from "./modes/ImpressionRanking";
import logo from "../../../src/assets/rondesuisselogo.png";
import ImpressionDone from "./modes/ImpressionDone";
import Countdown from "react-countdown";
import {useTranslation} from "react-i18next";
import {CounterState, ImpressionMode, TournamentStatus} from "../model/enum";

function Download() {

    const {t} = useTranslation();
    const tournamentStatus = useAppSelector(selectTournamentState);
    const countDownEndAt = useAppSelector(selectCountdownEnd);
    let impressionMode = useAppSelector(selectImpressionMode);
    const roundCount = useAppSelector(selectRoundCount);
    const dispatch = useAppDispatch();
    const counterState = useAppSelector(selectCounterState);
    const {tournamentName} = useAppSelector(selectTournamentOptions);

    const countDownRef = React.createRef<Countdown>();

    useEffect(() => {
        if (counterState === CounterState.PAUSED) {
            countDownRef.current?.pause();
        }
        if (counterState === CounterState.STOPPED) {
            countDownRef.current?.stop();
        }
        if (counterState === CounterState.STARTED) {
            countDownRef.current?.start();
        }
    }, [counterState])

    if (!roundCount) return <></>;

    return (
        <div className="download-page">
            <img className="logo" alt="logo" src={logo}/>

            {tournamentStatus !== TournamentStatus.DONE ? (
                <>
                    <h1>
                        {tournamentName ? tournamentName + " - " : null}
                        {t("roundCountLabel")}
                        {roundCount}
                    </h1>

                    {impressionMode === ImpressionMode.Pairing ? (
                        <ImpressionPairing/>
                    ) : (
                        <ImpressionRanking/>
                    )}
                </>
            ) : (
                <>
                    <h1>
                        {tournamentName ? tournamentName + " - " : null}
                        {t("finalRankingLabel")}
                    </h1>
                    <ImpressionDone/>
                </>
            )}
        </div>
    );
}

export default Download;
