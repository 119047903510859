import React from 'react';

interface ToggleSwitchProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({checked, onChange, disabled}) => {
    return (
        <div className={"toggle-switch " + (disabled ? "disabled" : null)}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <span className="slider"></span>
        </div>
    );
};

export default ToggleSwitch;