import {useAppSelector} from "../../logic/hooks";
import {selectRounds, selectTournamentOptions} from "../../redux/appSlice";
import {useTranslation} from "react-i18next";
import React from "react";
import {TeamDisplay} from "../Team";

export function RoundHistory(props: { removeLastRound: boolean }) {
    const {t} = useTranslation();
    const {useGoalAverage, teamSize} = useAppSelector(selectTournamentOptions);

    let rounds = [...useAppSelector(selectRounds)].sort((a, b) => b.id - a.id);

    if (rounds.length > 0 && props.removeLastRound) {
        rounds = rounds.slice(1, rounds.length);
    }

    if (!rounds) {
        return null;
    }

    <h2 style={{"marginTop": "60px"}}>{t('roundsHistory')}</h2>
    return (<>
        <h2 style={{"marginTop": "60px"}}>{t('roundsHistory')}</h2>

        {rounds.map((round) => (
            <div className="round" key={round.id}>
                <h3>{t('round')} #{round.id + 1}</h3>
                <table className="impression-table" border={1}>
                    <thead>
                    <tr>
                        <th>{t('table')}</th>
                        <th>{teamSize > 1 ? t('teams') : t('player')}</th>
                        {useGoalAverage ? <th>{t('score')}</th> : null}
                        <th>{t('W/D/L')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {round.tables.map((table, tableIndex) => (
                        <React.Fragment key={tableIndex}>
                            {table.teams.map((team, teamIndex) => (
                                <tr key={teamIndex}>
                                    {teamIndex === 0 ?
                                        <td className={"first"}>{tableIndex + 1}</td> : <td className="second"/>}
                                    <td><TeamDisplay teamData={team}/></td>
                                    {useGoalAverage ? <td>{team.score}</td> : null}
                                    <td>{team.win}/{team.draw}/{team.loss}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>

            </div>
        ))
        }</>)
}