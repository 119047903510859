import React from "react";
import {useTranslation} from "react-i18next";

function LanguageSelector() {
    const {i18n} = useTranslation();

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    return (
        <div className="language-selector">
            <button className="button link" onClick={() => changeLanguage("fr")}>FR</button>
            <button className="button link" onClick={() => changeLanguage("en")}>EN</button>
            <button className="button link" onClick={() => changeLanguage("de")}>DE</button>
        </div>
    );
}

export default LanguageSelector;