import {selectPlayers} from "../redux/appSlice";
import React from "react";
import {useAppSelector} from "../logic/hooks";
import {PlayerDisplay} from "./Player";
import {TeamData} from "../model/team";

export const TeamDisplay = (props: { teamData: TeamData }) => {
    const players = useAppSelector(selectPlayers);

    // if team is empty, return 'Bye'
    if (props.teamData.players.length === 0) {
        return <div>Bye</div>;
    }

    // if only one player in the team, return the player display
    if (props.teamData.players.length === 1) {
        let p = players.find(p => p.id === props.teamData.players[0].id);
        return <PlayerDisplay player={p}/>
    }

    // if more than one player in the team, add a comma between each player
    return <div>
        {props.teamData.players.map((player, index) => {
            let p = players.find(p => p.id === player.id);
            return <React.Fragment key={player.id}>
                <PlayerDisplay player={p}/>
                {index < props.teamData.players.length - 1}
            </React.Fragment>
        })}
    </div>
}


export const TeamPointsDisplay = (props: { teamData: TeamData }) => {
    const players = useAppSelector(selectPlayers);

    // Build the mean of the points of the players in the team
    let points = 0;
    props.teamData.players.map((player) => {
        let p = players.find(p => p.id === player.id);
        points += p?.points || 0;
    });
    points = Math.round(points / props.teamData.players.length);
    return <span>{points}</span>
}