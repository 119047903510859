import React from "react";
import {useAppSelector} from "../logic/hooks";
import {PlayerData} from "../model/player";
import {selectTournamentOptions} from "../redux/appSlice";

export const PlayerDisplay = (props: { player: PlayerData | undefined }) => {
    const {useGroup} = useAppSelector(selectTournamentOptions);

    let player = props.player;
    if (!player) {
        return <div>Bye</div>;
    }
    return <div>
        <span>{player.name}</span>
        {useGroup && player.group && <i> - {player.group} </i>}
    </div>
}