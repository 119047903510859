import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector, useT3Check} from "../../logic/hooks";
import {
    clearTournament,
    openModal,
    selectCountdownEnd,
    selectCounterState,
    selectGame,
    selectPlayerById,
    selectPlayers,
    selectRoundCount,
    selectRounds,
    selectTournamentOptions,
    selectTournamentState,
    setCountdownEnd,
    setCounterState,
    setPlayer,
    setRound,
    setRounds,
    setTournamentStatus,
} from "../../redux/appSlice";
import {EditSVG} from "../icons/EditIcon";
import {currentState, deepCopy} from "../../logic/utils";
import {
    clearRound,
    createTables,
    getOtherPlayers,
    getPlayersOrderedByPoints,
    getPlayerTableForRound,
    getTableTeamAndPlayerIndex,
    isTableDone,
    updatePlayerPoints,
    updatePlayerResistanceAndOpponents,
    validateTables
} from "../../logic/tournament";
import {CheckSVG} from "../icons/CheckSVG";
import useModalEventBus from "../modal/ModalBus";
import {Tooltip} from "react-tooltip";
import {Link} from "react-router-dom";
import Countdown, {zeroPad} from "react-countdown";
import {StopSVG} from "../icons/StopSVG";
import {PlaySVG} from "../icons/PlaySVG";
import {EnterSVG} from "../icons/EnterSVG";
import {LeaveSVG} from "../icons/LeaveSVG";
import {FullscreenSVG} from "../icons/FullscreenSVG";
import {useTranslation} from "react-i18next";
import {PauseSVG} from "../icons/PauseSVG";
import JsPDF from 'jspdf';
import {DownloadSVG} from "../icons/DownloadSVG";
import Version from "../ui/Version";
import Logo from "../ui/Logo";
import LanguageSelector from "../ui/LanguageSelector";
import {Round} from "../../model/round";
import {CounterState, TournamentStatus} from "../../model/enum";
import {PlayerData} from "../../model/player";
import {TableData} from "../../model/table";
import {PlayerDisplay} from "../Player";


const RunningTournament = () => {
    const dispatch = useAppDispatch();

    const players = useAppSelector(selectPlayers);
    const rounds = useAppSelector(selectRounds);
    const tournamentState = useAppSelector(selectTournamentState);
    const countDownEndAt = useAppSelector(selectCountdownEnd);
    const game = useAppSelector(selectGame);
    const counterState = useAppSelector(selectCounterState);
    const {t} = useTranslation();
    const [elapsedTime, setElapsedTime] = useState(0);
    const [counterStartedAt, setCounterStartedAt] = useState(0);
    const roundCount = useAppSelector(selectRoundCount);
    const [activeRoundIndex, setActiveRoundIndex] = useState(roundCount - 1);
    const [editingTableIndex, setEditingTableIndex] = useState(-1);
    const {
        useExternalRanking,
        maxRounds,
        tournamentName,
        useGroup,
        useGoalAverage,
        roundDuration
    } = useAppSelector(selectTournamentOptions);

    const countDownRef = React.createRef<Countdown>();
    useT3Check();

    useEffect(() => {
        if (counterState === CounterState.PAUSED) {
            countDownRef.current?.pause();
        }
        if (counterState === CounterState.STOPPED) {
            countDownRef.current?.stop();
        }
        if (counterState === CounterState.STARTED) {
            countDownRef.current?.start();
        }
    }, [counterState])

    const clearTournamentAction = function (args: { answer: boolean }) {
        dispatch(clearTournament())
    }

    useModalEventBus(RunningTournament, clearTournamentAction);

    const handleDropPlayer = (player: PlayerData) => {
        player = {...player};
        player.dropped = !player.dropped;
        dispatch(setPlayer(player));
    };

    const pointssHaveBeenSet = (round: Round) => {
        for (let table of round.tables) {
            for (let team of table.teams) {
                if (team.win || team.loss || team.draw) {
                    return true;
                }
            }
        }

        return false;
    }

    const allPointssHaveBeenSet = () => {
        let round = rounds[roundCount - 1];
        if (!round) {
            return true;
        }
        for (let table of round.tables) {
            for (let team of table.teams) {
                if (!team.win && !team.loss && !team.draw) {
                    return false;
                }
            }
        }

        return true;
    }

    function removeLastRound() {
        dispatch(setRounds(rounds.slice(0, roundCount - 1)));
    }

    function runPairingAgain() {

        removeLastRound();
        updateAllPlayersPoints();
        updateAllPlayersResistance();

        // Can re-run only the last round

        let players = selectPlayers(currentState());
        let tables = createTables(players, (roundCount - 1), Math.ceil(Math.random() * (players.length / 2)));

        if (!validateTables(tables)) {
            tables = createTables(players, (roundCount - 1), Math.ceil(Math.random() * (players.length * 2)));
        }

        dispatch(setRound({
                              tables: tables, done: false, id: roundCount - 1
                          }))

        updateAllPlayersPoints();
        updateAllPlayersResistance();
    }

    function handleEndOfTournament() {
        dispatch(setTournamentStatus(TournamentStatus.DONE));
    }

    function runPairing() {
        let tables = createTables(players, roundCount);

        dispatch(setRound({
                              tables: tables, done: false, id: roundCount
                          }))

        setActiveRoundIndex(roundCount);
    }

    function cancelResult(tableIndex: number) {
        let round = clearRound(rounds[activeRoundIndex], tableIndex);
        dispatch(setRound(round));
        updatePointsForPlayersInTable(tableIndex, round);
        return deepCopy(round) as Round;
    }

    function setScore(number: number, tableIndex: number, teamIndex: number) {
        let round = deepCopy(selectRounds(currentState())[activeRoundIndex]) as Round;

        let opponentScore = 0;
        for (let [index, team] of round.tables[tableIndex].teams.entries()) {
            if (index !== teamIndex) {
                opponentScore = team.score;
            }
            if (index === teamIndex) {
                team.score = number;
            }
        }

        dispatch(setRound(round));

        if (number > opponentScore) {
            setWin(tableIndex, teamIndex, true);
        } else if (number < opponentScore) {
            setLoss(tableIndex, teamIndex, true);
        } else {
            setDraw(tableIndex, teamIndex, true);
        }

    }

    function setWin(tableIndex: number, teamIndex: number, force: boolean = false) {

        let round = selectRounds(currentState())[activeRoundIndex];

        if (!force) {
            for (let [i, team] of round.tables[tableIndex].teams.entries()) {
                if (i === teamIndex && team.win === 1) {
                    cancelResult(tableIndex);
                    return;
                }
            }
        }

        round = clearRound(round, tableIndex);

        for (let [index, team] of round.tables[tableIndex].teams.entries()) {
            if (index === teamIndex) {
                team.win = 1;
            } else {
                team.loss = 1;
            }
        }

        dispatch(setRound(round));
        updatePointsForPlayersInTable(tableIndex, round);

    }

    function setDraw(tableIndex: number, teamIndex: number, force: boolean = false) {
        let round = selectRounds(currentState())[activeRoundIndex];

        if (!force) {
            for (let [index, player] of round.tables[tableIndex].teams.entries()) {
                if (index === teamIndex && player.draw === 1) {
                    cancelResult(tableIndex);
                    return;
                }
            }
        }

        round = clearRound(round, tableIndex);

        for (let [index, team] of round.tables[tableIndex].teams.entries()) {
            team.draw = 1;
        }
        dispatch(setRound(round));
        updatePointsForPlayersInTable(tableIndex, round);

    }

    function setLoss(tableIndex: number, teamIndex: number, force: boolean = false) {
        let round = selectRounds(currentState())[activeRoundIndex];

        if (!force) {
            for (let [index, team] of round.tables[tableIndex].teams.entries()) {
                if (index === teamIndex && team.loss === 1) {
                    cancelResult(tableIndex);
                    return;
                }
            }
        }

        round = clearRound(round, tableIndex);

        for (let [index, team] of round.tables[tableIndex].teams.entries()) {

            if (index === teamIndex) {
                team.loss = 1;
            } else {
                team.win = 1;
            }
        }
        dispatch(setRound(round));

        updatePointsForPlayersInTable(tableIndex, round);
    }

    function updatePointsForPlayersInTable(tableIndex: number, round: Round) {
        for (let player of round.tables[tableIndex].teams.reduce((acc: {
            id: string
        }[], team) => acc.concat(team.players), [])) {
            let newPlayer = updatePlayerPoints(
                deepCopy(selectPlayerById(player.id)(currentState())),
                selectRounds(currentState()));
            dispatch(setPlayer(newPlayer));
        }
        updateAllPlayersResistance();
    }

    function updateAllPlayersPoints() {
        // Reset all pointss
        for (let player of selectPlayers(currentState())) {
            dispatch(
                setPlayer(
                    updatePlayerPoints(
                        deepCopy(selectPlayerById(player.id)(currentState())),
                        selectRounds(currentState()))));
        }
    }

    function updateAllPlayersResistance() {
        for (let player of selectPlayers(currentState())) {
            dispatch(
                setPlayer(
                    updatePlayerResistanceAndOpponents(
                        deepCopy(selectPlayerById(player.id)(currentState())),
                        selectRounds(currentState()))));
        }
    }

    function handleEditTable(tableIndex: number) {
        setEditingTableIndex(tableIndex);
    }

    function handleChangePlayer(tableIndex: number, p1: string, p2: string) {

        let round = deepCopy(rounds[activeRoundIndex]);
        let p1Table = getPlayerTableForRound(round, p1) as TableData;
        let p1Index = getTableTeamAndPlayerIndex(p1Table, p1);
        let p2Table = getPlayerTableForRound(round, p2) as TableData;
        let p2Index = getTableTeamAndPlayerIndex(p2Table, p2);

        if (p1Index !== undefined && p2Index !== undefined) {
            p1Table.teams[p1Index[0]].players[p1Index[1]].id = p2;
            p2Table.teams[p2Index[0]].players[p2Index[1]].id = p1;
            dispatch(setRound(round));
        }

    }

    function handleStopTimer() {
        dispatch(setCountdownEnd(0))
        dispatch(setCounterState(CounterState.STOPPED));
        setElapsedTime(0);
        setCounterStartedAt(0);

    }


    function getCounterEndTime() {
        return (Date.now() + roundDuration * 60 * 1000) - elapsedTime
    }

    function handleStartPauseTimer() {
        if (counterState === CounterState.STARTED) {
            dispatch(setCounterState(CounterState.PAUSED));
            setElapsedTime(Date.now() - counterStartedAt);

        } else {
            dispatch(setCounterState(CounterState.STARTED));
            dispatch(setCountdownEnd(getCounterEndTime()));
            setCounterStartedAt(Date.now());

        }
    }

    const generatePDF = () => {

        const report = new JsPDF({
                                     orientation: "landscape",
                                     unit: "px",
                                     format: [1400, 1000]
                                 });
        const el = document.getElementById('pdf');

        report.html(el as any).then(() => {
            report.save('Tournament_' + tournamentName + '.pdf');
        });
    }

    return (
        <div className="container">
            <header>
                <Logo/>
                <Version/>
                <LanguageSelector/>

                <h2>{tournamentName ? tournamentName : t("tournamentInProgress")}</h2>
                <div className={"tournament-actions"}>
                    <button
                        type="submit"
                        className="link link-small"
                        onClick={() => {
                            dispatch(setTournamentStatus(TournamentStatus.EDITING))
                        }}
                    >
                        {t("settings")}
                    </button>
                    <br/>
                    <button
                        type="submit"
                        className="link link-small delete"
                        onClick={() => {
                            dispatch(openModal({
                                                   modalText: t("areYouSureDeleteTournament"),
                                                   buttonActions: [{
                                                       label: t("yes"),
                                                       closeOnClick: true,
                                                       targetComponent: RunningTournament.name,
                                                       method: clearTournamentAction.name,
                                                   }, {
                                                       label: t("no"),
                                                       closeOnClick: true
                                                   }],
                                               }))
                        }}
                    >
                        {t("deleteTournament")}
                    </button>
                </div>
            </header>

            <div className="box">
                <h3>{t("ranking")}</h3>
                <div className="players-list">
                    <table className="players-list">
                        <thead>
                        <tr>
                            <th><span className="txt">#</span></th>
                            <th><span className="txt">{t("player")}</span></th>
                            <th>
                  <span className="txt">{t("points")}
                      <span className="help"
                            data-tooltip-id="help-points"
                            data-tooltip-content={t("pointsAccumulationTooltip") || ''}/>
                  </span>
                            </th>
                            <th>
                  <span className="txt">{t("W/D/L")}
                      <span className="help"
                            data-tooltip-id="help-ved"
                            data-tooltip-content={t("winDrawLossTooltip") || ''}/>
                  </span>
                            </th>

                            {useGoalAverage ? <>
                                {game !== "saga" ? <th>
                    <span className="txt">{t("score")}
                        <span className="help"
                              data-tooltip-id="help-score"
                              data-tooltip-content={t("gameScoreAccumulationTooltip") || ''}/>
                    </span>
                                </th> : null}
                                <th>
                    <span className="txt">
                      {game === "saga" ? "GA" : "GD"}
                        <span className="help"
                              data-tooltip-id="help-gd"
                              data-tooltip-content={t("gameScoreDifferenceTooltip") || ''}/>
                    </span>
                                </th>
                            </> : null}

                            <th><span className="txt">{t("resistance")}
                                <span className="help"
                                      data-tooltip-id="help-resistance"
                                      data-tooltip-content={t("opponentVictoryPointsTooltip") || ''}/>
                </span>
                            </th>
                            {useExternalRanking ? <th><span className="txt">{t("externalRank")}</span></th> : ''}
                            <th align="right"/>
                        </tr>
                        </thead>
                        <tbody>
                        {getPlayersOrderedByPoints(players.slice())
                            .map((player, index) => (
                                <tr key={player.id} className={player.dropped ? "dropped" : ""}>
                                    <td>{index + 1}.</td>
                                    <td>{player.name}{useGroup && player.group ?
                                        <small> - <i> {player.group}</i></small> : null}</td>
                                    <td>{player.points}</td>
                                    <td>{player.win}/{player.draw}/{player.loss}</td>
                                    {useGoalAverage ? <>
                                        {game !== "saga" ? <td>
                                            {player.score}
                                        </td> : null}
                                        <td>
                                            {player.gd}
                                        </td>
                                    </> : null}
                                    <td>{player.resistance}</td>
                                    {useExternalRanking ? <td>{player.externalRank}</td> : ''}
                                    <td align="right">
                                        <button title="drop" type="button" className="drop-button icon-button"
                                                onClick={() => handleDropPlayer(player)}>
                                            {player.dropped ?
                                                <EnterSVG/>
                                                :
                                                <LeaveSVG/>
                                            }
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {tournamentState === TournamentStatus.DONE ? <div className="round-actions">
                        <Link to="impression" target={"_blank"}>
                            <button className="secondary print">{t("show")}&nbsp;
                                <FullscreenSVG/>
                            </button>

                        </Link>


                        <button
                            onClick={generatePDF}
                            className="secondary print">
                            <DownloadSVG/>
                        </button>
                    </div> : null}
                </div>
            </div>
            {rounds[activeRoundIndex] ?
                <>
                    <div className="box">
                        <h3>{t("round")} {activeRoundIndex + 1}</h3>
                        <div className="tables">
                            {rounds[activeRoundIndex].tables.map((tableData, index) => (
                                <div className={"table " + (isTableDone(tableData) ? " done" : "")} key={index}>
                                    <div className={"table-name"}>
                                        <span>{t("table")} {index + 1}</span>
                                        <br/>
                                        {editingTableIndex !== index ?
                                            <button className="link" onClick={() => handleEditTable(index)}><EditSVG/>
                                            </button> :
                                            <button className="link" onClick={() => handleEditTable(-1)}><CheckSVG/>
                                            </button>}
                                    </div>

                                    <div className="players">
                                        {tableData.teams.map((team, teamIndex) => {
                                            return (
                                                <div className={"team " + (team.win ? "winner" : "")}
                                                     key={teamIndex}>
                                                    <div>
                                                        {team.players.map(
                                                            (p, pIndex) => {
                                                                let fullPlayer = selectPlayerById(p.id)(currentState());

                                                                return (<div className={"player"} key={p.id}>
                                                                    {
                                                                        editingTableIndex === index ? (
                                                                            <select
                                                                                onChange={(e) => handleChangePlayer(index, p.id, e.target.value)}>
                                                                                <option>{selectPlayerById(p.id)(currentState()).name}</option>
                                                                                {getOtherPlayers(p.id, players).map(otherPlayer => (
                                                                                    <option key={otherPlayer.id}
                                                                                            value={otherPlayer.id}>{otherPlayer.name}
                                                                                    </option>))}
                                                                            </select>) : (
                                                                            <PlayerDisplay player={fullPlayer}/>
                                                                        )}
                                                                </div>)
                                                            }
                                                        )}
                                                    </div>

                                                    <div className={"actions"}>
                                                        {useGoalAverage ? <div className="score-entry">
                                                            <label>{t("score")} :</label>
                                                            <input
                                                                type="number"
                                                                value={team.score}
                                                                onChange={(e) => setScore(parseInt(e.target.value), index, teamIndex)}
                                                            />
                                                        </div> : null}
                                                        <button disabled={tournamentState === TournamentStatus.DONE}
                                                                onClick={() => setWin(index, teamIndex)}
                                                                className={"win " + (team.win ? "active" : "")}>{t("winFirstLetter")}
                                                        </button>
                                                        <button disabled={tournamentState === TournamentStatus.DONE}
                                                                onClick={() => setDraw(index, teamIndex)}
                                                                className={"draw " + (team.draw ? "active" : "")}>{t("drawFirstLetter")}
                                                        </button>
                                                        <button disabled={tournamentState === TournamentStatus.DONE}
                                                                onClick={() => setLoss(index, teamIndex)}
                                                                className={"loss " + (team.loss ? "active" : "")}>{t("lossFirstLetter")}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {tournamentState !== TournamentStatus.DONE ?
                            (activeRoundIndex === roundCount - 1 ?
                                <div className="round-actions">


                                    <div className="counter-buttons">
                                        <div className="countdown">
                                            <Countdown
                                                autoStart={false}
                                                ref={countDownRef}
                                                date={countDownEndAt ? countDownEndAt : getCounterEndTime()}
                                                renderer={({
                                                               hours,
                                                               minutes,
                                                               seconds,
                                                               completed
                                                           }) => (
                                                    <span> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)
                                                }>
                                                <span>00:00:00</span>
                                            </Countdown>
                                        </div>

                                        <button
                                            onClick={handleStartPauseTimer}
                                            className="secondary pair-again">
                                            {counterState === CounterState.STARTED ? <PauseSVG/> : <PlaySVG/>}
                                        </button>
                                        {counterState !== CounterState.STOPPED ? <button
                                            onClick={handleStopTimer}
                                            className="secondary pair-again">
                                            <StopSVG/>
                                        </button> : null}
                                    </div>
                                    <button disabled={pointssHaveBeenSet(rounds[activeRoundIndex])}
                                            onClick={runPairingAgain}
                                            className="secondary pair-again">{t("restartPairing")}
                                    </button>
                                    <Link to="impression" target={"_blank"}>
                                        <button className="secondary print">{t("show")}&nbsp;
                                            <FullscreenSVG/>
                                        </button>
                                    </Link>
                                    <button
                                        onClick={generatePDF}
                                        className="secondary print">
                                        <DownloadSVG/>
                                    </button>
                                </div>
                                : <div className="done-round">{t("roundDone")}</div>)
                            : null}
                    </div>
                    <div className="round-list">
                        <span>{t("rounds")}:</span>
                        {[...Array(roundCount).keys()].map(k =>
                                                               <button key={k} onClick={() => setActiveRoundIndex(k)}
                                                                       className={"link"}>{k + 1}</button>
                        )}
                    </div>
                </> : null}


            {tournamentState !== TournamentStatus.DONE ?
                (roundCount < maxRounds ?
                    <button disabled={!allPointssHaveBeenSet()} className="start-button" onClick={runPairing}>
                        {t("launchNewRound")}
                    </button> :
                    <button disabled={!allPointssHaveBeenSet()} className="start-button"
                            onClick={handleEndOfTournament}>{t("validateEndOfTournament")}</button>) :
                <div className="tournament-finished-message">
                    {t("tournamentFinished")}
                    <br/>
                    <button
                        type="submit"
                        className="link link-small"
                        onClick={() => {
                            dispatch(openModal({
                                                   modalText: t("areYouSureDeleteTournamentInformations"),
                                                   buttonActions: [{
                                                       label: t("yes"),
                                                       closeOnClick: true,
                                                       targetComponent: RunningTournament.name,
                                                       method: clearTournamentAction.name,
                                                   }, {
                                                       label: t("no"),
                                                       closeOnClick: true
                                                   }
                                                   ],
                                               }))
                        }}
                    >
                        {t("newTournament")}
                    </button>
                </div>}

            <Tooltip id="help-points"/>
            <Tooltip id="help-ved"/>
            <Tooltip id="help-resistance"/>
            <Tooltip id="help-score"/>
            <Tooltip id="help-gd"/>

        </div>
    );
};

export default RunningTournament;
