import React from "react";
import {useAppSelector} from "../../logic/hooks";
import {getPlayersOrderedByName, getPlayerTableForRound, isTableDone} from "../../logic/tournament";
import {useTranslation} from "react-i18next";
import {selectPlayers, selectRoundCount, selectRounds, selectTournamentOptions} from "../../redux/appSlice";
import {RoundHistory} from "../../components/tournament/RoundHistory";
import {PlayerDisplay} from "../../components/Player";
import {TeamDisplay, TeamPointsDisplay} from "../../components/Team";
import {TeamData} from "../../model/team";
import {PlayerData} from "../../model/player";

function ImpressionPairing() {
    const {t} = useTranslation();
    const players = useAppSelector(selectPlayers);
    const rounds = useAppSelector(selectRounds);
    const roundCount = useAppSelector(selectRoundCount);
    const {teamSize} = useAppSelector(selectTournamentOptions);
    if (!roundCount) return <></>;

    return (
        <>
            <table className="impression-table" border={1}>
                <thead>
                <tr>
                    <th style={{maxWidth: "20px"}}>{t("table")}</th>
                    <th colSpan={teamSize > 1 ? 2 : 1}>{teamSize > 1 ? t("playerAndTeammates") : t("player")}</th>
                    <th>{teamSize > 1 ? t("opponents") : t("opponent")}</th>
                    <th style={{maxWidth: "60px"}}>{t("points")}</th>
                </tr>
                </thead>
                <tbody>
                {getPlayersOrderedByName(players).map((player) => {
                    let table = getPlayerTableForRound(rounds[roundCount - 1], player.id);

                    if (!table) {
                        return null;
                    }

                    let teamData = table.teams.find(t => t.players.find(
                        (p) => p.id === player.id
                    )) as TeamData;

                    let opponentTeamData = table.teams.find(t => !t.players.find(
                        (p) => p.id === player.id
                    )) as TeamData;

                    let tableIndex = rounds[roundCount - 1].tables.indexOf(table) + 1;


                    return (
                        <tr
                            className={
                                (isTableDone(table) ? " done " : "") +
                                (teamData.win ? " win " : null) +
                                (teamData.loss ? " loss " : null) +
                                (teamData.draw ? " draw " : null)
                            }
                            key={player.id}
                        >
                            <td>{tableIndex}</td>
                            <td>
                                {<PlayerDisplay player={player}/>}
                            </td>
                            {teamSize > 1 ? (
                                <td>
                                    {teamData.players.filter(p => p.id !== player.id).map((c) => (
                                        <PlayerDisplay key={c.id}
                                                       player={players.find(p => p.id === c.id) as PlayerData}/>
                                    ))}
                                </td>
                            ) : null}
                            <td>{<TeamDisplay teamData={opponentTeamData}/>}</td>
                            <td>
                                <TeamPointsDisplay teamData={teamData}/> -&nbsp;
                                <TeamPointsDisplay teamData={opponentTeamData}/>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <RoundHistory removeLastRound={true}/>
        </>
    );
}

export default ImpressionPairing;
