import {Action, configureStore, Middleware, ThunkAction} from '@reduxjs/toolkit';
import {appSlice, initialState} from './appSlice';

const version = initialState.version;
let stateKey = 'state';
if (version) {
    stateKey += '-' + version.num;
}

export const getStateKey = () => {
    return stateKey;
}

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem(stateKey);
        if (serializedState === null) {
            return undefined; // Return undefined to use the initial state from the reducer
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.error('Error loading state from local storage:', err);
        return undefined;
    }
};

const saveStateToLocalStorageMiddleware: Middleware = (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(stateKey, serializedState);
    } catch (err) {
        console.error('Error saving state to local storage:', err);
    }
    return result;
};

const initialStateFromLocalStorage: any = loadStateFromLocalStorage();
if ((initialStateFromLocalStorage && initialStateFromLocalStorage.version) && (initialStateFromLocalStorage.version.num !== initialState.version.num)) {
    // Alert the user that the state is corrupted and needs to be reset, ask him if he wants to reset it or go to the previous version
    // eslint-disable-next-line no-restricted-globals
    confirm("The data is for an older version of the app, please check the changelogs.");
}


export const store = configureStore({
                                        reducer: {
                                            "app": appSlice.reducer,
                                        },
                                        preloadedState: initialStateFromLocalStorage ? initialStateFromLocalStorage : undefined,
                                        middleware: [saveStateToLocalStorageMiddleware]

                                    });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
