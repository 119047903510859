import React, {FC, useEffect} from 'react';
import {eventModalBus} from "./ModalBus";
import {useAppDispatch} from "../../logic/hooks";
import {closeModal} from "../../redux/appSlice";

export interface ModalAction {
    targetComponent: string;
    method: string;
    args: any[]
}

export interface ModalData {
    id?: string
    modalText: string;
    closeAfterMs?: number;
    buttonActions: ButtonAction[];
}

export interface ButtonAction {
    label: string;
    targetComponent?: string;
    method?: string;
    args?: any
    closeOnClick?: boolean
}

export interface ModalProps {
    modalData: ModalData;
}

const Modal: FC<ModalProps> = (props) => {

    const dispatch = useAppDispatch();


    const handleClick = (button: ButtonAction) => {
        if (button.targetComponent && button.method) {
            eventModalBus.emit(button.targetComponent + "_" + button.method, button.args);
        }
        if (button.closeOnClick === true) {
            dispatch(closeModal(props.modalData.id as string))
        }
    };

    useEffect(() => {
        if (props.modalData.closeAfterMs) {
            setTimeout(function () {
                dispatch(closeModal(props.modalData.id as string))
            }, props.modalData.closeAfterMs)
        }
    });

    return (
        <div className="modal">
            <div className="modal-content">
                <p>{props.modalData.modalText}</p>
            </div>
            <div className="modal-buttons">
                {props.modalData.buttonActions.map((button, index) => (
                    <button
                        key={index}
                        className="modal-button"
                        onClick={() => handleClick(button)}
                    >
                        {button.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Modal;