import {AnyAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {ModalData} from "../components/modal/Modal";
import {getUniqueId} from "../logic/utils";
import {PlayerData, PlayerStartData} from "../model/player";
import {CounterState, ImpressionMode, TournamentStatus} from "../model/enum";
import {Round} from "../model/round";
import {TournamentOptions} from "../model/tournament";


export interface AppState {
    version: {
        num: string,
        date: string
    }
    tournamentState: TournamentStatus
    rounds: Round[]
    players: PlayerData[]
    modals: ModalData[]
    impressionMode: ImpressionMode
    countDownEndAt: number
    game: string;
    counterState: CounterState
    tournamentOptions: TournamentOptions
}

export const initialState: AppState = {
    version: {
        num: "1.1",
        date: "2024/10/06"
    },
    tournamentState: TournamentStatus.PREPARING,
    players: [],
    rounds: [],
    modals: [],
    impressionMode: ImpressionMode.Pairing,
    countDownEndAt: 0,
    game: "",
    counterState: CounterState.PAUSED,
    tournamentOptions: {
        allowToMeetSameOpponentAgain: false,
        tournamentName: "",
        separateFavoritesOnFirstRound: false,
        roundDuration: 30,
        teamSize: 1,
        winPoints: 3,
        lossPoints: 0,
        drawPoints: 1,
        playersOfSameGroupCantMeet: false,
        useExternalRanking: false,
        maxRounds: 0,
        useGoalAverage: false,
        useGroup: false
    }
};


export const appSlice = createSlice({
                                        name: 'app',
                                        initialState,
                                        // The `reducers` field lets us define reducers and generate associated actions
                                        reducers: {
                                            setTournamentOption: (state: AppState, action: PayloadAction<Partial<TournamentOptions>>) => {
                                                state.tournamentOptions = {...state.tournamentOptions, ...action.payload};
                                            },

                                            setGame: (state: AppState, action: PayloadAction<string>) => {
                                                state.game = action.payload;
                                            },

                                            setCountdownEnd: (state: AppState, action: PayloadAction<number>) => {
                                                state.countDownEndAt = action.payload;
                                            },

                                            setRound: (state: AppState, action: PayloadAction<Round>) => {
                                                let round = action.payload;
                                                state.rounds[round.id] = round;
                                            },

                                            setRounds: (state: AppState, action: PayloadAction<Round[]>) => {
                                                let rounds = action.payload;
                                                state.rounds = rounds;
                                            },

                                            setTournamentStatus: (state: AppState, action: PayloadAction<TournamentStatus>) => {
                                                state.tournamentState = action.payload;
                                            },
                                            addPlayer: (state: AppState, action: PayloadAction<PlayerData>) => {
                                                state.players.push(action.payload)
                                            },
                                            setPlayer: (state: AppState, action: PayloadAction<PlayerData>) => {
                                                let existingPlayer = state.players.find(p => p.id === action.payload.id);
                                                if (existingPlayer) {
                                                    state.players[state.players.indexOf(existingPlayer)] = action.payload
                                                }
                                            },
                                            setPlayers: (state: AppState, action: PayloadAction<PlayerStartData[]>) => {
                                                state.players = action.payload.map(e => {
                                                    return {
                                                        gd: 0,
                                                        score: 0,
                                                        points: 0,
                                                        resistance: 0,
                                                        win: 0,
                                                        loss: 0,
                                                        draw: 0,
                                                        dropped: false,
                                                        victoryPoints: 0,
                                                        opponents: [],
                                                        comrades: []
                                                        , ...e
                                                    }
                                                });
                                            },
                                            setModals: (state: AppState, action: PayloadAction<ModalData[]>) => {
                                                state.modals = action.payload;
                                            },
                                            openModal: (state: AppState, action: PayloadAction<ModalData>) => {
                                                action.payload.id = getUniqueId();
                                                state.modals.push(action.payload);
                                                return state
                                            },
                                            closeModal: (state: AppState, action: PayloadAction<string>) => {
                                                state.modals = state.modals.filter(m => m.id !== action.payload);
                                            },

                                            setCounterState: (state, action: PayloadAction<CounterState>) => {
                                                state.counterState = action.payload;
                                            },

                                            setImpressionMode: (state, action: PayloadAction<ImpressionMode>) => {
                                                state.impressionMode = action.payload;
                                            },
                                            clearTournament: (state, action: PayloadAction) => {
                                                state.tournamentOptions.tournamentName = "";
                                                state.tournamentState = TournamentStatus.PREPARING;
                                                state.players = [];
                                                state.rounds = [];
                                            },

                                        }
                                    });

interface MatchActions {
    setGame: (game: string) => AnyAction;
    clearTournament: () => AnyAction
    addPlayer: (p: PlayerData) => AnyAction
    setPlayer: (p: PlayerData) => AnyAction
    setPlayers: (players: PlayerStartData[]) => AnyAction
    setModals: (modals: ModalData[]) => AnyAction
    closeModal: (id: string) => AnyAction
    openModal: (modal: ModalData) => AnyAction
    setTournamentStatus: (tournamentStatus: TournamentStatus) => AnyAction;
    setRound: (round: Round) => AnyAction;
    setRounds: (rounds: Round[]) => AnyAction;
    setCountdownEnd: (at: number) => AnyAction;
    setImpressionMode: (mode: ImpressionMode) => AnyAction
    setCounterState: (counterState: CounterState) => AnyAction;
    setTournamentOption: (option: Partial<TournamentOptions>) => AnyAction;
}

export const {
    addPlayer,
    setPlayer,
    setPlayers,
    setGame,
    closeModal,
    openModal,
    setModals,
    clearTournament,
    setTournamentStatus,
    setImpressionMode,
    setRound,
    setRounds,
    setCountdownEnd,
    setCounterState,
    setTournamentOption
} = appSlice.actions as MatchActions;

export const selectVersion = (state: RootState) => state.app.version;

export const selectPlayers = (state: RootState) => state.app.players;
export const selectRounds = (state: RootState) => state.app.rounds;
export const selectTournamentState = (state: RootState) => state.app.tournamentState;
export const selectTournamentOptions = (state: RootState) => state.app.tournamentOptions;
export const selectTeamSize = (state: RootState) => state.app.tournamentOptions.teamSize || 1;
export const selectModals = (state: RootState) => state.app.modals;
export const selectRoundCount = (state: RootState) => state.app.rounds.length;
export const selectImpressionMode = (state: RootState) => state.app.impressionMode;
export const selectCountdownEnd = (state: RootState) => state.app.countDownEndAt;
export const selectGame = (state: RootState) => state.app.game;
export const selectCounterState = (state: RootState) => state.app.counterState;

export const selectPlayerById = (id: string) => (state: RootState) =>
    state.app.players.find((player) => player.id === id) as PlayerData;

export default appSlice.reducer;
