import {
    selectGame,
    selectPlayers,
    selectRoundCount,
    selectRounds,
    selectTournamentOptions,
} from "../../redux/appSlice";
import React from "react";
import {useAppSelector} from "../../logic/hooks";
import {getPlayersOrderedByPoints, getPlayerTableForRound, isTableDone} from "../../logic/tournament";
import {useTranslation} from "react-i18next";
import {RoundHistory} from "../../components/tournament/RoundHistory";
import {TeamData} from "../../model/team";
import {PlayerDisplay} from "../../components/Player";

function ImpressionRanking() {
    const {t} = useTranslation();
    const players = useAppSelector(selectPlayers);
    const rounds = useAppSelector(selectRounds);
    const game = useAppSelector(selectGame);
    const roundCount = useAppSelector(selectRoundCount);
    const {useGroup, useGoalAverage} = useAppSelector(selectTournamentOptions);
    if (!roundCount) return <></>;

    return (
        <>
            <table className="impression-table" border={1}>
                <thead>
                <tr>
                    <th style={{maxWidth: "10px"}}>#</th>
                    <th>{t("player")}</th>
                    <th style={{maxWidth: "20px"}}>{t("table")}</th>
                    <th style={{maxWidth: "60px"}}>{t("points")}</th>
                    <th style={{maxWidth: "60px"}}>{t("W/D/L")}</th>
                    {useGoalAverage ? (
                        <>
                            {game !== "saga" ? <th>{t("score")}</th> : null}
                            <th>{game === "saga" ? t("GA") : t("GD")}</th>
                        </>
                    ) : null}
                    <th style={{maxWidth: "60px"}}>{t("resistance")}</th>
                </tr>
                </thead>
                <tbody>
                {getPlayersOrderedByPoints(players).map((player, index) => {
                    let table = getPlayerTableForRound(rounds[roundCount - 1], player.id);
                    if (!table) {
                        return null;
                    }
                    let teamData = table.teams.find(t => t.players.find(
                        (p) => p.id === player.id
                    )) as TeamData;
                    let tableIndex = rounds[roundCount - 1].tables.indexOf(table) + 1;
                    return (
                        <tr
                            className={
                                (isTableDone(table) ? " done " : "") +
                                (teamData.win ? " win " : null) +
                                (teamData.loss ? " loss " : null) +
                                (teamData.draw ? " draw " : null)
                            }
                            key={player.id}
                        >
                            <td>{index + 1}</td>
                            <td><PlayerDisplay player={player}/></td>
                            <td>{tableIndex}</td>
                            <td>{player.points}</td>
                            <td>
                                {player.win}/{player.draw}/{player.loss}
                            </td>
                            {useGoalAverage ? (
                                <>
                                    {game !== 'saga' ? <td>{player.score}</td> : null}
                                    <td>{player.gd}</td>
                                </>
                            ) : null}
                            <td>{player.resistance}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <RoundHistory removeLastRound={true}/>
        </>
    );
}

export default ImpressionRanking;
