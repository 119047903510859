import React from 'react';
import {useSelector} from "react-redux";
import {selectVersion} from "../../redux/appSlice";
import {useTranslation} from "react-i18next";


function Version() {
    let version = useSelector(selectVersion);
    let {t} = useTranslation();

    let update = null;

    let versionDate = new Date(version.date.replace('/', '-'));
    // get update from localstorage and check if it's different than version, if yes display "Mise à jour ! Check changelog!"
    if (localStorage.getItem('version') !== version.num || (Date.now() < versionDate.getTime() + (86400000))) {
        console.log("Mise à jour ! Check changelog!");
        update = <div className="update">{t('updateCheckChangelog')}</div>
        localStorage.setItem('version', version.num);
    }

    return (
        <>

            <span className="version">
                {update}
                Version {version.num} / {version.date} <a
                href={"changelog"}>(changelog)</a></span>
        </>
    );
};

export default Version;