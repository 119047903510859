import {selectTournamentState} from "../redux/appSlice";
import SetupTournament from "../components/tournament/SetupTournament";
import RunningTournament from "../components/tournament/RunningTournament";
import React from "react";
import {useAppSelector} from "../logic/hooks";

import {useTranslation} from "react-i18next";
import Footer from "./Footer";
import {TournamentStatus} from "../model/enum";

function Home() {

    const tournamentStatus = useAppSelector(selectTournamentState)
    const [t] = useTranslation();

    return (
        <>
            {tournamentStatus === TournamentStatus.PREPARING ||
            tournamentStatus === TournamentStatus.EDITING ?
                <SetupTournament/>
                :
                <RunningTournament/>
            }
            <Footer/>
        </>
    );

}

export default Home;
