export enum CounterState {
    STARTED = "started",
    PAUSED = "paused",
    STOPPED = "stopped"
}

export enum ImpressionMode {
    Pairing = "pairing",
    Ranking = "ranking"
}


export enum TournamentStatus {
    PREPARING,
    EDITING,
    STARTED,
    DONE
}