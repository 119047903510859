import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    fr: {
        translation: {

            "logo": "Logo",
            "newTournament": "Nouveau tournoi",
            "editTournament": "Modification du tournoi",
            "returnToTournament": "Revenir au tournoi",
            "players": "Joueurs",
            "playerName": "Nom du joueur...",
            "groupName": "Nom du groupe...",
            "rank": "Rang",
            "add": "Ajouter",
            "name": "Nom",
            "group": "Groupe",
            "settings": "Paramètres",
            "tournamentName": "Nom du tournoi",
            "placeholderTournamentName": "Confrontation X...",
            "pointsPerWin": "Points par victoire",
            "pointsPerDraw": "Points par égalité",
            "pointsPerLoss": "Points par défaite",
            "numRounds": "Nombre de rondes",
            "roundDuration": "Durée des rondes (minutes)",
            "addGroupNamePerPlayer": "Ajouter un nom de groupe par joueur",
            "useExternalRank": "Ajouter un rang externe pour le premier tour",
            "separateFavoritesOnFirstRound": "Séparer les favoris au premier tour",
            "playerOfSameGroupCantMeet": "Les joueurs du même groupe ne peuvent pas s'affronter",
            "addScoreAndGD": "Ajouter le score et la différence des scores (GD) pour départager les victoires",
            "useSpecificGameRules": "Utiliser les règles spécifiques au jeu",
            "noRules": "Pas de règles",
            "saga": "Saga",
            "gameRulesInfo": "Ce logiciel est fourni tel quel, sans garantie d'aucune sorte. Les données du tournoi sont stockées localement dans le cache de votre navigateur. Pour toute question ou suggestion, n'hésitez pas à me contacter. Bon jeu!",
            "gameNotInList": "Votre jeu n'est pas dans la liste?",
            "contactMe": "Contactez-moi :)",
            "launchNewTournament": "Lancer le nouveau tournoi",
            "launchNewRound": "Lancer une ronde",
            "validateEndOfTournament": "Valider la fin du tournoi",
            "tournamentInProgress": "Tournoi en cours",
            "tournamentFinished": "Ce tournoi est terminé",
            "deleteTournament": "Supprimer le tournoi",
            "areYouSureDeleteTournament": "Voulez-vous supprimer ce tournoi ?",
            "areYouSureDeleteTournamentInformations": "Voulez-vous supprimer les informations du dernier tournoi ?",
            "yes": "Oui",
            "no": "Non",
            "playerList": "Liste des joueurs",
            "pointsAccumulationTooltip": "Accumulation des points de victoire, égalités et défaites.",
            "winDrawLossTooltip": "Nombre de victoires, égalités et défaites.",
            "gameScoreAccumulationTooltip": "Accumulation du score de jeu, p.ex le nombre de buts marqués.",
            "gameScoreDifferenceTooltip": "Mesure utilisant les différence de score de jeu à la fin de chaque ronde.",
            "opponentVictoryPointsTooltip": "Accumulation des points de victoire des adversaires rencontrés.",
            "externalRank": "Rang ext.",
            "round": "Ronde",
            "table": "Table",
            "edit": "Editer",
            "player": "Joueur",
            "playerAndTeammates": "Joueur et coéquipiers",
            "opponent": "Adversaire",
            "opponents": "Adversaires",
            "actions": "Actions",
            "points": "Points",
            "score": "Score",
            "resistance": "Resistance",
            "W/D/L": "V/E/D",
            "win": "Victoire",
            "draw": "Egalité",
            "loss": "Défaite",
            "winFirstLetter": 'V',
            "drawFirstLetter": 'E',
            "lossFirstLetter": 'D',
            "roundActions": "Actions de ronde",
            "startStop": "Commencer / Stop",
            "restartPairing": "Relancer le pairing",
            "show": "Afficher",
            "roundDone": "Cette ronde est terminée.",
            "rounds": "Rondes",
            "impressionPageTitle": "Impression",
            "roundCountLabel": "Ronde #",
            "countdownLabel": "Décompte ",
            "victoryLabel": "Victoire",
            "drawLabel": "Égalité",
            "lossLabel": "Défaite",
            "displayModeLabel": "Affichage ",
            "simpleModeButton": "simple",
            "ranking": "Classement",
            "rankingModeButton": "classement",
            "finalRankingLabel": "Classement final",
            "partners": "Partenaires",
            roundsHistory: "Historique des rondes",
            download: "Télécharger",
            areYouSureNewTournamentWithPlayerList: "Êtes-vous sûr de vouloir créer un nouveau tournoi avec {{playersCount}} joueurs de {{providerName}} ?",
            teamSize: "Taille des équipes",
            allowToMeetSameOpponentAgain: "Autoriser à rencontrer le même adversaire si nécéssaire",
            AllowsYouToEnterAGroupNameForEachPlayer: "Permet d'entrer un nom de groupe pour chaque joueur.",
            ifNecessaryAsLastOptionWillReuseSameOpponentToBuildAFullTeam: "Si nécessaire, en dernier recours, le système choisira un adversaire déjà rencontré pour former une équipe complète.",
            teams: "Équipes",
            updateCheckChangelog: "Mise à jour ! Veuillez lire le changelog.",

        }
    },
    en: {
        translation: {
            teams: "Teams",
            logo: "Logo",
            launchNewRound: "Launch a round",
            ranking: "Ranking",
            newTournament: "New Tournament",
            editTournament: "Edit Tournament",
            returnToTournament: "Return to Tournament",
            players: "Players",
            "playerAndTeammates": "Player and teammates",
            playerName: "Player name...",
            groupName: "Group name...",
            rank: "Rank",
            add: "Add",
            name: "Name",
            group: "Group",
            settings: "Settings",
            tournamentName: "Tournament Name",
            placeholderTournamentName: "Challenge X...",
            pointsPerWin: "Points per Win",
            pointsPerDraw: "Points per Draw",
            pointsPerLoss: "Points per Loss",
            numRounds: "Number of Rounds",
            roundDuration: "Round Duration (minutes)",
            addGroupNamePerPlayer: "Add a group name per player",
            useExternalRank: "Use external rank for the first round",
            separateFavoritesOnFirstRound: "Separate favorites on the first round",
            playerOfSameGroupCantMeet: "Players of the same group can't fight against each other",
            addScoreAndGD: "Add score and goal difference (GD) to decide victories",
            useSpecificGameRules: "Use specific game rules",
            noRules: "No rules",
            saga: "Saga",
            gameNotInList: "Is your game not in the list?",
            gameRulesInfo: "This software is provided as is, without any warranty of any kind. The tournament data is stored locally in your browser cache. For any question or suggestion, feel free to contact me. Enjoy the game!",
            contactMe: "Contact me :)",
            launchNewTournament: "Start new Tournament",
            tournamentInProgress: "Tournament in progress",
            deleteTournament: "Delete Tournament",
            areYouSureDeleteTournament: "Are you sure you want to delete this tournament?",
            areYouSureNewTournamentWithPlayerList: "Are you sure you want to create a new tournament with {{playersCount}} players from {{providerName}} ?",
            yes: "Yes",
            no: "No",
            playerList: "Player List",
            pointsAccumulationTooltip: "Accumulation of victory points, draws, and losses.",
            winDrawLossTooltip: "Number of wins, draws, and losses.",
            gameScoreAccumulationTooltip: "Accumulation of game score, e.g., the number of goals scored.",
            gameScoreDifferenceTooltip: "Measure using the difference in game score at the end of each round.",
            opponentVictoryPointsTooltip: "Accumulation of victory points of the opponents faced.",
            externalRank: "External Rank",
            round: "Round",
            table: "Table",
            edit: "Edit",
            player: "Player",
            actions: "Actions",
            score: "Score",
            win: "Win",
            draw: "Draw",
            loss: "Loss",
            roundActions: "Round Actions",
            startStop: "Start / Stop",
            restartPairing: "Restart Pairing",
            show: "Show",
            roundDone: "This round is done.",
            rounds: "Rounds",
            points: "Points",
            resistance: "Resistance",
            "W/D/L": "W/D/L",
            winFirstLetter: "W",
            drawFirstLetter: "D",
            lossFirstLetter: "L",
            tournamentFinished: "Tournament finished",
            areYouSureDeleteTournamentInformations: "Do you want to delete the information of the last tournament?",
            impressionPageTitle: "Impression",
            roundCountLabel: "Round #",
            countdownLabel: "Countdown",
            victoryLabel: "Victory",
            drawLabel: "Draw",
            lossLabel: "Loss",
            displayModeLabel: "Display Mode",
            simpleModeButton: "Simple",
            rankingModeButton: "Ranking",
            finalRankingLabel: "Final Ranking",
            opponent: "Opponent",
            opponents: "Opponents",
            partners: "Partners",
            download: "Download",
            roundsHistory: "Round History",
            teamSize: "Team Size",
            allowToMeetSameOpponentAgain: "Allow to meet the same opponent again if necessary",
            AllowsYouToEnterAGroupNameForEachPlayer: "Allows you to enter a group name for each player.",
            ifNecessaryAsLastOptionWillReuseSameOpponentToBuildAFullTeam: "If necessary, as a last resort, the system will choose an opponent already met to form a full team.",
            updateCheckChangelog: "Update! Check the changelog!",

        },
    },
    de: {
        translation: {
            "logo": "Logo",
            "opponent": "Gegner",
            "opponents": "Gegner",
            "newTournament": "Neues Turnier",
            "editTournament": "Turnier bearbeiten",
            "returnToTournament": "Zum Turnier zurückkehren",
            "players": "Spieler",
            "playerName": "Name des Spielers...",
            "groupName": "Gruppenname...",
            "rank": "Rang",
            "add": "Hinzufügen",
            "name": "Name",
            "group": "Gruppe",
            "settings": "Einstellungen",
            "tournamentName": "Turniername",
            "placeholderTournamentName": "Turnier X...",
            "pointsPerWin": "Punkte pro Sieg",
            "pointsPerDraw": "Punkte pro Unentschieden",
            "pointsPerLoss": "Punkte pro Niederlage",
            "numRounds": "Anzahl der Runden",
            "roundDuration": "Rundendauer (Minuten)",
            "addGroupNamePerPlayer": "Gruppennamen pro Spieler hinzufügen",
            "useExternalRank": "Externe Rangliste für die erste Runde verwenden",
            "separateFavoritesOnFirstRound": "Favoriten in der ersten Runde trennen",
            "addScoreAndGD": "Spielstand und Tordifferenz (GD) hinzufügen, um Siege zu entscheiden.",
            "playerOfSameGroupCantMeet": "Spieler derselben Gruppe können nicht gegeneinander kämpfen",
            "useSpecificGameRules": "Spezifische Spielregeln verwenden",
            "noRules": "Keine Regeln",
            "gameRulesInfo": "Diese Software wird wie sie ist, ohne jegliche Garantie jeglicher Art bereitgestellt. Die Turnierdaten werden lokal im Cache Ihres Browsers gespeichert. Bei Fragen oder Anregungen können Sie mich gerne kontaktieren. Viel Spaß beim Spielen!",
            "saga": "Saga",
            "gameNotInList": "Ist Ihr Spiel nicht in der Liste?",
            "contactMe": "Kontaktiere mich :)",
            "launchNewTournament": "Neues Turnier starten",
            "tournamentInProgress": "Turnier läuft",
            "deleteTournament": "Turnier löschen",
            "areYouSureDeleteTournament": "Möchten Sie dieses Turnier wirklich löschen?",
            "yes": "Ja",
            "no": "Nein",
            "playerList": "Spielerliste",
            "pointsAccumulationTooltip": "Anhäufung von Siegpunkten, Unentschieden und Niederlagen.",
            "winDrawLossTooltip": "Anzahl der Siege, Unentschieden und Niederlagen.",
            "gameScoreAccumulationTooltip": "Anhäufung des Spielstands, z. B. die Anzahl der erzielten Tore.",
            "gameScoreDifferenceTooltip": "Messung mithilfe der Spielstandsdifferenz am Ende jeder Runde.",
            "opponentVictoryPointsTooltip": "Anhäufung der Siegpunkte der gegnerischen Spieler.",
            "externalRank": "Externer Rang",
            "round": "Runde",
            "table": "Tisch",
            "edit": "Bearbeiten",
            "player": "Spieler",
            "playerAndTeammates": "Spieler und Teamkollegen",
            "actions": "Aktionen",
            "score": "Spielstand",
            "win": "Gewonnen",
            "draw": "Unentschieden",
            "loss": "Verloren",
            "roundActions": "Rundenaktionen",
            "startStop": "Start / Stop",
            "restartPairing": "Pairing neu starten",
            "show": "Anzeigen",
            "roundDone": "Diese Runde ist beendet.",
            "rounds": "Runden",
            "points": "Punkte",
            "resistance": "Widerstand",
            "W/D/L": "S/U/N",
            "ranking": "Rangfolge",
            "winFirstLetter": "S",
            "drawFirstLetter": "U",
            "lossFirstLetter": "N",
            "tournamentFinished": "Turnier beendet",
            "areYouSureDeleteTournamentInformations": "Möchten Sie die Informationen des letzten Turniers wirklich löschen?",
            "impressionPageTitle": "Drucken",
            "roundCountLabel": "Runde #",
            "countdownLabel": "Countdown",
            "victoryLabel": "Sieg",
            "drawLabel": "Unentschieden",
            "lossLabel": "Niederlage",
            "displayModeLabel": "Anzeigemodus",
            "simpleModeButton": "einfach",
            "rankingModeButton": "Rangfolge",
            "finalRankingLabel": "Endrangliste",
            "partners": "Partner",
            download: "Download",
            launchNewRound: "Runde starten",
            roundsHistory: "Rundenverlauf",
            "validateEndOfTournament": "Bestätigen Sie das Ende des Turniers",
            areYouSureNewTournamentWithPlayerList: "Möchten Sie wirklich ein neues Turnier mit {{playersCount}} Spielern von {{providerName}} erstellen?",
            teamSize: "Teamgröße",
            allowToMeetSameOpponentAgain: "Erlauben, den gleichen Gegner wieder zu treffen, wenn nötig",
            AllowsYouToEnterAGroupNameForEachPlayer: "Ermöglicht es Ihnen, für jeden Spieler einen Gruppennamen einzugeben.",
            ifNecessaryAsLastOptionWillReuseSameOpponentToBuildAFullTeam: "Wenn nötig, wird das System als letzte Option einen bereits getroffenen Gegner auswählen, um ein vollständiges Team zu bilden.",
            "teams": "Teams",
            updateCheckChangelog: "Update! Überprüfen Sie das Changelog!",
        }
    }
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
              fallbackLng: 'en',

              resources,
              debug: true,
              interpolation: {
                  escapeValue: false // react already safes from xss
              }
          });

export default i18n;