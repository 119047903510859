import React from "react";
import LanguageSelector from "../components/ui/LanguageSelector";
import Version from "../components/ui/Version";
import Footer from "./Footer";
import Logo from "../components/ui/Logo";
import {useTranslation} from "react-i18next";

function Changelog() {

    let currentLanguage = useTranslation().i18n.language;

    return (
        <>
            <div className="container">
                <header>
                    <LanguageSelector/>
                    <Logo/>
                    <Version/>
                    <h2>Changelog</h2>
                </header>
                <div className="content">


                    {currentLanguage === 'fr' && <>
                        <h3>Version 1.1 (04-05-2024) </h3>
                        <ul>
                            <li>Ajout de l'option "taille de l'équipe". Vous pouvez spécifier une taille d'équipe, par
                                exemple 2 contre 2. Par défaut, c'est 1.
                            </li>
                            <li>Ajout de l'option pour permettre au système de sélectionner un adversaire déjà rencontré
                                pour constituer une équipe adverse complète si nécessaire.
                            </li>
                        </ul>

                        <h3>Version 1.0.3 (04-05-2024) <a href="/versions/1.0.3/" rel="nofollow">(ancienne version
                            ici)</a></h3>
                        <ul>
                            <li>Permettre de choisir si les joueurs du même groupe peuvent s'affronter.</li>
                            <li>Affiche les noms des groupes à côté du joueur dans le mode "Affichage externe".</li>
                        </ul>

                        <h3>Version 1.0.2 (11-10-2023)</h3>
                        <ul>
                            <li>Les tournois tabletop peuvent être importés en utilisant /tid/id dans l'URL.</li>
                        </ul>

                        <h3>Version 1.0.1 (11-10-2023)</h3>
                        <ul>
                            <li>Correction d'un bug qui empêche d'apparier correctement les joueurs dans certaines
                                situations.
                            </li>
                        </ul>

                        <h3>Version 1.0.0 (26-06-2023)</h3>
                        <ul>
                            <li>Première version.</li>
                        </ul>
                    </>}

                    {currentLanguage === 'en' && <>   <h3>Version 1.1 (2024-05-04) </h3>
                        <ul>
                            <li>Add the 'team size' option. You can specify a team size, for instance 2 vs 2. Default is
                                1.
                            </li>
                            <li>The system can be allowed to select a previously met opponent to build a full opponent
                                team if necessary.
                            </li>
                        </ul>

                        <h3>Version 1.0.3 (2024-05-04) <a href="/versions/1.0.3/" rel="nofollow">(old version here)</a>
                        </h3>
                        <ul>
                            <li>Allow to choose whether players in the same group can fight against each other</li>
                            <li>Show group names next to player in print display</li>
                        </ul>

                        <h3>Version 1.0.2 (2023-10-11)</h3>
                        <ul>
                            <li>Tabletop tournament can be imported using /tid/id in url</li>
                        </ul>

                        <h3>Version 1.0.1 (2023-10-11)</h3>
                        <ul>
                            <li>Fixed a bug where it didn't pair players properly in some situations</li>
                        </ul>

                        <h3>Version 1.0.0 (26-06-2023)</h3>
                        <ul>
                            <li>Initial release</li>
                        </ul>

                    </>}


                    {currentLanguage === 'de' && <>
                        <h3>Version 1.1 (04-05-2024) </h3>
                        <ul>
                            <li>Hinzufügen der Option "Teamgröße". Sie können eine Teamgröße angeben, z. B. 2 gegen 2.
                                Standardmäßig ist es 1.
                            </li>
                            <li>Hinzufügen der Option, dem System zu erlauben, einen bereits bekannten Gegner
                                auszuwählen, um bei Bedarf ein vollständiges gegnerisches Team zu bilden.
                            </li>
                        </ul>

                        <h3>Version 1.0.3 (04-05-2024) <a href="/versions/1.0.3/" rel="nofollow">(alte Version hier)</a>
                        </h3>
                        <ul>
                            <li>Die Möglichkeit, auszuwählen, ob Spieler derselben Gruppe gegeneinander antreten
                                können.
                            </li>
                            <li>Zeigt die Gruppennamen neben dem Spieler im "Externe Anzeige"-Modus an.</li>
                        </ul>

                        <h3>Version 1.0.2 (11-10-2023)</h3>
                        <ul>
                            <li>Tabletop-Turniere können über /tid/id in der URL importiert werden.</li>
                        </ul>

                        <h3>Version 1.0.1 (11-10-2023)</h3>
                        <ul>
                            <li>Behebung eines Fehlers, der in einigen Situationen die richtige Paarung der Spieler
                                verhinderte.
                            </li>
                        </ul>

                        <h3>Version 1.0.0 (26-06-2023)</h3>
                        <ul>
                            <li>Erstveröffentlichung.</li>
                        </ul>
                    </>}


                </div>

            </div>

            <Footer/>
        </>
    );

}

export default Changelog;
