import {selectGame, selectPlayerById, selectTournamentOptions,} from "../redux/appSlice";
import {currentState, deepCopy, remove, shuffle} from "./utils";
import {Round} from "../model/round";
import {TableData} from "../model/table";
import {PlayerData, PlayerStartData} from "../model/player";
import {TeamData} from "../model/team";

export function getPlayerTableForRound(round: Round, playerId: string): TableData | undefined {
    if (!round) {
        return undefined;
    }
    let tables = round.tables;
    for (let i = 0; i < tables.length; i++) {
        for (let team of tables[i].teams) {
            if (team.players.find(p => p.id === playerId)) {
                return tables[i];
            }
        }
    }
}

export function updatePlayerPoints(player: PlayerData, rounds: Round[]) {

    let {winPoints, drawPoints, lossPoints} = selectTournamentOptions(currentState());

    let points = 0;
    let loss = 0;
    let win = 0;
    let draw = 0;
    let score = 0;
    for (let round of rounds) {
        for (let table of round.tables) {
            for (let team of table.teams) {
                if (team.players.find(p => p.id === player.id)) {
                    score += team.score;
                    if (team.win) {
                        win += team.win;
                        points += winPoints * team.win;
                    } else if (team.draw) {
                        draw += team.draw;
                        points += drawPoints * team.draw;
                    } else if (team.loss) {
                        loss += team.loss;
                        points += lossPoints * team.loss;
                    }
                }
            }
        }
    }
    player.draw = draw;
    player.loss = loss;
    player.win = win;
    player.points = points;
    player.score = score;

    return player;
}

export function findTableWithPlayer(playerId: string, round: Round) {
    for (let table of round.tables) {
        for (let team of table.teams) {
            if (team.players.find(p => p.id === playerId)) {
                return table
            }
        }
    }
}

export function sagaDiff(score1: number, score2: number, win: boolean) {
    let diff = Math.abs(score1 - score2);

    const ecartTable = [
        [0, 3, 6, 10, 15, 20, 25, 30, 35, 36],
        [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
        [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    ];

    for (let i = 0; i < ecartTable[0].length; i++) {
        if (diff <= ecartTable[0][i]) {
            return win ? ecartTable[1][i] : ecartTable[2][i]
        }
    }

    return win ? 19 : 1;
}

export function updatePlayerResistanceAndOpponents(player: PlayerData, rounds: Round[]) {
    let resistance = 0;
    let opponents = [];
    let gd = 0;

    for (let round of rounds) {
        let tableWithPlayer = findTableWithPlayer(player.id, round);

        if (tableWithPlayer) {
            const teamData = tableWithPlayer.teams.find(team => team.players.find(p => p.id === player.id)) as TeamData;
            const otherTeamData = tableWithPlayer.teams.find(team => !team.players.find(p => p.id === player.id)) as TeamData;

            switch (selectGame(currentState())) {
                case 'saga':
                    gd += sagaDiff(teamData.score, otherTeamData.score, teamData.win > 0);
                    break;
                default:
                    gd += teamData.score - otherTeamData.score;

            }
            // Do the mean of resistances of opponents
            for (let opponent of otherTeamData.players) {
                resistance += selectPlayerById(opponent.id)(currentState()).points;
                opponents.push(opponent.id);
            }

        }

    }


    player.resistance = resistance
    player.opponents = opponents;
    player.gd = gd;
    return player

}

export function getTableTeamAndPlayerIndex(table: TableData, playerId: string) {
    for (let indexT = 0; indexT < table.teams.length; indexT++) {
        let team = table.teams[indexT];
        for (let indexP = 0; indexP < team.players.length; indexP++) {
            if (team.players[indexP].id === playerId) {
                return [indexT, indexP];
            }
        }
    }
}

export function getOtherPlayers(playerId: string, players: PlayerData[]) {
    return players.filter(p => p.id !== playerId)
}

export function getPlayersOrderedByPoints(players: PlayerData[]) {
    let {useGoalAverage} = selectTournamentOptions(currentState());

    return [...players].sort((a, b) => {
        return (b.points !== a.points) ? b.points - a.points : (useGoalAverage && (b.gd !== a.gd) ? b.gd - a.gd : b.resistance - a.resistance); // Sort by points in descending order
    });

}

export function getPlayersOrderedByName(players: PlayerData[]) {
    return [...players].sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
}

export function getPlayersOrderedByExternalRank(players: PlayerData[]) {
    return [...players].sort((a, b) => {
        return b.externalRank - a.externalRank; // Sort by rank in descending order
    });
}

export function getRoundMax(players: PlayerStartData[]) {
    return Math.max(0, Math.ceil(Math.log2(players.length)))
}

export function findAPlayerToMeet(fromPlayersPool: PlayerData[], player ?: PlayerData, conditions: {
    fromSameGroupOnly?: boolean,
    notSameGroup?: boolean,
    findStrongest?: boolean
    findWeakest?: boolean,
    fromWeakestHalf?: boolean,
    findAverage?: boolean
    useExternalRanking?: boolean,
    allowToMeetSameOpponent?: boolean,
    orderBy?: 'score' | 'externalRank'
} = {}, poolSize = 1) {

    if (fromPlayersPool.length === 0) {
        return undefined;
    }

    let alreadyMetOpponents = player ? player.opponents : [];

    if (alreadyMetOpponents && !conditions.allowToMeetSameOpponent) {
        // Remove already met opponents from the pool
        fromPlayersPool = fromPlayersPool.filter(p => alreadyMetOpponents.indexOf(p.id) < 0);
    }

    let group = player ? player.group : null;
    if (group && conditions.fromSameGroupOnly) {
        fromPlayersPool = fromPlayersPool.filter(p => p.group === group);
    }

    if (group && conditions.notSameGroup) {
        fromPlayersPool = fromPlayersPool.filter(p => p.group !== group);
    }

    shuffle(fromPlayersPool);


    if (conditions.findStrongest) {
        if (conditions.useExternalRanking) {
            fromPlayersPool = getPlayersOrderedByExternalRank(fromPlayersPool);

            if (conditions.fromWeakestHalf) {
                fromPlayersPool = fromPlayersPool.slice(-Math.ceil(fromPlayersPool.length / 2));
                poolSize = fromPlayersPool.length;
            }
        } else {
            fromPlayersPool = getPlayersOrderedByPoints(fromPlayersPool);
        }
    }

    // Cut the pool size
    fromPlayersPool = fromPlayersPool.slice(0, poolSize);

    // Shuffle it again
    shuffle(fromPlayersPool);

    // return the player
    return fromPlayersPool[0];

}


export function buildATable(players: PlayerData[], roundCount: number, poolSize = 1, conditions: {
    allowToMeetSameOpponent?: boolean
} = {}): TableData {
    let {useGroup, playersOfSameGroupCantMeet, useExternalRanking, teamSize} = selectTournamentOptions(currentState());

    const table: TableData = {
        teams: [
            {players: [], score: 0, win: 0, draw: 0, loss: 0},
            {players: [], score: 0, win: 0, draw: 0, loss: 0}
        ]
    }

    let team1 = table.teams[0];
    let team2 = table.teams[1];
    for (let i = 0; i < teamSize; i++) {

        if (i === 0 || i === 3 || i === 5) {
            team1 = table.teams[0];
            team2 = table.teams[1];
        }

        if (i === 1 || i === 2 || i === 4) {
            team1 = table.teams[1];
            team2 = table.teams[0];
        }

        let strongestPlayer = findAPlayerToMeet(players, undefined, {
            findStrongest: true,
            useExternalRanking: useExternalRanking && (roundCount === 0), // first turn only
        }, poolSize);

        if (!strongestPlayer) {
            return table;
        }

        // Got the strongest player for the team1
        team1.players.push({id: strongestPlayer.id});
        remove(players, strongestPlayer);

        // Now find the opponent
        let strongestOpponent = findAPlayerToMeet(players, strongestPlayer, {
            findStrongest: true,
            notSameGroup: playersOfSameGroupCantMeet && useGroup
        }, poolSize);

        if (!strongestOpponent && conditions.allowToMeetSameOpponent) {
            // Another Attempt
            strongestOpponent = findAPlayerToMeet(players, strongestPlayer, {
                findStrongest: true,
                allowToMeetSameOpponent: true,
                notSameGroup: playersOfSameGroupCantMeet && useGroup
            }, poolSize);
        }

        if (!strongestOpponent) {
            return table;
        }

        remove(players, strongestOpponent);

        // @ts-ignore
        team2.players.push({id: strongestOpponent.id});
    }

    return table;

}

export function validateTables(tables: TableData[]) {
    let {teamSize} = selectTournamentOptions(currentState());

    for (let i = 0; i < tables.length; i++) {
        for (let teamIndex = 0; teamIndex < tables[i].teams.length; teamIndex++) {
            if (tables[i].teams[teamIndex].players.length < teamSize) {
                console.log("Table " + i + ", team " + teamIndex + " has less than " + teamSize + " players");
                return false;
            }
        }
    }
    return true;
}

export function isTableDone(table: TableData) {
    for (let team of table.teams) {
        if (team.win !== 0 || team.draw !== 0 || team.loss !== 0) {
            return true;
        }
    }
    return false;
}

export function clearRound(round: Round, tableIndex: number) {
    round = deepCopy(round) as Round;
    for (let team of round.tables[tableIndex].teams) {
        team.draw = 0;
        team.loss = 0;
        team.win = 0;
    }
    return round;
}

export function createTables(players: PlayerData[], roundCount: number, initialPoolSize = 1) {
    let {allowToMeetSameOpponentAgain} = selectTournamentOptions(currentState());
    let tables: TableData[] = [];
    let conditions = {allowToMeetSameOpponent: false};
    let poolSize = initialPoolSize;

    function buildTables(withPoolSize: number = 0) {
        tables = [];
        let playersToPair = [...players].filter(p => !p.dropped);
        while (playersToPair.length > 0) {
            tables.push(buildATable(playersToPair, roundCount, Math.max(1, withPoolSize), conditions));
            withPoolSize--;
        }
    }

    function attemptToBuildAllTables() {
        poolSize = initialPoolSize;
        let test = 0;
        while (poolSize <= (players.length + 5)) {
            while ((test < (10 * poolSize)) && !validateTables(tables)) {
                buildTables(poolSize);
                test++;
            }

            if (validateTables(tables)) {
                break;
            }
            poolSize++;
            test = 0;
        }
    }

    buildTables(poolSize);
    attemptToBuildAllTables();

    // If unable to create tables, try with more liberty
    if (!validateTables(tables) && allowToMeetSameOpponentAgain) {
        conditions.allowToMeetSameOpponent = allowToMeetSameOpponentAgain;
        attemptToBuildAllTables(); // again...
    }

    return tables;
}
