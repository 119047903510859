import React from 'react';
import logo from "../../../assets/rondesuisselogo.png";
import {Link} from "react-router-dom";


function Logo() {

    return (
        <Link to=""><img className="logo" alt="logo" src={logo}/></Link>
    );
};

export default Logo;