import React from "react";
import {useAppSelector} from "../../logic/hooks";
import {useTranslation} from "react-i18next";
import {selectGame, selectPlayers, selectRounds, selectTournamentOptions} from "../../redux/appSlice";
import {getPlayersOrderedByPoints} from "../../logic/tournament";
import {RoundHistory} from "../../components/tournament/RoundHistory";

function ImpressionDone() {
    const {t} = useTranslation();
    const players = useAppSelector(selectPlayers);
    const {useGoalAverage} = useAppSelector(selectTournamentOptions);
    const game = useAppSelector(selectGame);
    const rounds = [...useAppSelector(selectRounds)].sort((a, b) => b.id - a.id);

    return (
        <>
            <table className="impression-table" border={1}>
                <thead>
                <tr>
                    <th style={{maxWidth: "10px"}}>#</th>
                    <th>{t("player")}</th>
                    <th style={{maxWidth: "60px"}}>{t("points")}</th>
                    {useGoalAverage ? (
                        <>
                            {game !== 'saga' ? <th> {t("score")}</th> : null}
                            <th>{game === 'saga' ? "GA" : "GD"}</th>
                        </>
                    ) : null}
                    <th style={{maxWidth: "60px"}}>{t("resistance")}</th>
                    <th style={{maxWidth: "60px"}}>{t("W/D/L")}</th>
                </tr>
                </thead>
                <tbody>
                {getPlayersOrderedByPoints(players).map((player, index) => {
                    return (
                        <tr key={player.name}>
                            <td>{index + 1}</td>
                            <td>{player.name}</td>
                            <td>{player.points}</td>
                            {useGoalAverage ? (
                                <>
                                    {game !== 'saga' ? <td>{player.score}</td> : null}
                                    <td>{player.gd}</td>
                                </>
                            ) : null}
                            <td>{player.resistance}</td>
                            <td>{player.win}/{player.draw}/{player.loss}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            <RoundHistory removeLastRound={false}/>

        </>
    );
}

export default ImpressionDone;
