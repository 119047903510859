import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../logic/hooks";
import {
    selectCountdownEnd,
    selectCounterState,
    selectImpressionMode,
    selectRoundCount,
    selectTournamentOptions,
    selectTournamentState,
    setImpressionMode,
} from "../redux/appSlice";
import ImpressionPairing from "./modes/ImpressionPairing";
import ImpressionRanking from "./modes/ImpressionRanking";
import logo from "../../../src/assets/rondesuisselogo.png";
import ImpressionDone from "./modes/ImpressionDone";
import Countdown, {zeroPad} from "react-countdown";
import {useTranslation} from "react-i18next";
import {CounterState, ImpressionMode, TournamentStatus} from "../model/enum";
import {getStateKey} from "../redux/store";

function Impression() {
    useEffect(() => {
        window.addEventListener("storage", function (e) {
            if (e.key?.includes('i18next')) return;
            if (e.key === getStateKey()) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        });
    }, []);

    const {t} = useTranslation();
    const tournamentStatus = useAppSelector(selectTournamentState);
    const countDownEndAt = useAppSelector(selectCountdownEnd);

    let impressionMode = useAppSelector(selectImpressionMode);
    const {tournamentName} = useAppSelector(selectTournamentOptions);
    const roundCount = useAppSelector(selectRoundCount);
    const dispatch = useAppDispatch();
    const counterState = useAppSelector(selectCounterState);

    const countDownRef = React.createRef<Countdown>();

    useEffect(() => {
        if (counterState === CounterState.PAUSED) {
            countDownRef.current?.pause();
        }
        if (counterState === CounterState.STOPPED) {
            countDownRef.current?.stop();
        }
        if (counterState === CounterState.STARTED) {
            countDownRef.current?.start();
        }
    }, [counterState])

    if (!roundCount) return <></>;

    return (
        <div className="impression-page">
            <img className="logo" alt="logo" src={logo}/>

            {tournamentStatus !== TournamentStatus.DONE ? (
                <>
                    <h1>
                        {tournamentName ? tournamentName + " - " : null}
                        {t("roundCountLabel")}
                        {roundCount}
                    </h1>

                    {countDownEndAt > 0 ? (
                        <div className={"countdown-print"}>
                            <Countdown
                                autoStart={false}
                                ref={countDownRef}
                                date={countDownEndAt}
                                renderer={({hours, minutes, seconds, completed}) => (
                                    <span>
                                        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
                                )}
                            >
                                <span>00:00:00</span>
                            </Countdown>
                        </div>
                    ) : null}

                    <div className="legend">
                        <div className={"win"}>{t("victoryLabel")}</div>
                        <div className={"draw"}>{t("drawLabel")}</div>
                        <div className={"loss"}>{t("lossLabel")}</div>
                    </div>

                    <div className="mode">
                        {t("displayModeLabel")}{" "}
                        <button
                            onClick={() => dispatch(setImpressionMode(ImpressionMode.Pairing))}
                            className={
                                "button link link-small " +
                                (impressionMode === ImpressionMode.Pairing ? "active" : null)
                            }
                        >
                            {t("simpleModeButton")}
                        </button>
                        {" "}
                        /&nbsp;
                        <button
                            onClick={() => dispatch(setImpressionMode(ImpressionMode.Ranking))}
                            className={
                                "button link link-small " +
                                (impressionMode === ImpressionMode.Ranking ? "active" : null)
                            }
                        >
                            {t("rankingModeButton")}
                        </button>
                    </div>

                    {impressionMode === ImpressionMode.Pairing ? (
                        <ImpressionPairing/>
                    ) : (
                        <ImpressionRanking/>
                    )}
                </>
            ) : (
                <>
                    <h1>
                        {tournamentName ? tournamentName + " - " : null}
                        {t("finalRankingLabel")}
                    </h1>
                    <ImpressionDone/>
                </>
            )}
        </div>
    );
}

export default Impression;
