import {v4 as uuidv4} from 'uuid';
import {store} from "../redux/store";
import axios from "axios";
import * as cheerio from 'cheerio';
import {PlayerStartData} from "../model/player";

export function romanize(num: number) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--) { // @ts-ignore
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    }
    return Array(+digits.join("") + 1).join("M") + roman;
}

export function getUniqueId() {
    return uuidv4();
}

export function ucfirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const colorShade = (col: string, amt: number) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b]: any = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
}

export function shuffle(array: any[]) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function remove(array: any[], value: any) {
    if (array.indexOf(value) !== -1) { // Make sure the value exists
        array.splice(array.indexOf(value), 1);
    }
}

export function deepCopy(object: any) {
    return JSON.parse(JSON.stringify(object))
}

export function currentState() {
    return store.getState();
}

export function getTidFromUrl() {
    const url = window.location.href;
    const tidIndex = url.indexOf('tid/');
    if (tidIndex !== -1) {
        const tid = url.slice(tidIndex + 4);
        return tid;
    }
    return null;
}

export function readT3Table(tid: string): Promise<PlayerStartData[]> {
    // Fetch the HTML content of the external website

    return axios.get(' https://larondesuisse.com/req.php?t3id=' + tid)
        .then((response) => {
            // Parse the HTML using Cheerio
            const $ = cheerio.load(response.data);

            // Locate the table element bassed on its selector
            const table = $('td.main').find('table').first();

            const rows = table.find('tr');
            const headerRow = rows.first();
            const headers = headerRow.find('th').map((index, header) => $(header).text().toLowerCase()).get();
            const rowsAr: any[] = rows.toArray();


            const data = rowsAr.slice(1).map((row, index) => {
                const columns = $(row).find('td').toArray();
                return columns.map((column, index) => $(column).text().trim());
            })

            let players: PlayerStartData[] = [];
            data.forEach((data: any, index) => {
                let entry: PlayerStartData = {
                    id: getUniqueId(),
                    name: "",
                    group: "",
                    externalRank: 0
                };
                headers.forEach((header, columnIndex) => {
                    (entry as any)[header] = data[columnIndex];

                    if (header === 'ntr') {
                        entry.externalRank = parseInt(data[columnIndex]) || 0;
                    }

                    if (header === 'army') {
                        entry.group = data[columnIndex];
                    }
                });

                if (entry.name) {
                    players.push(
                        entry
                    );
                }
            })


            // Set the extracted table data in state
            return players;
        })
        .catch(error => {
            return []
        });
}