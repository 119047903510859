import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {AppDispatch, RootState} from '../redux/store';
import {EffectCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {clearTournament, openModal, setPlayers, setTournamentOption} from "../redux/appSlice";
import {getRoundMax} from "./tournament";
import useModalEventBus from "../components/modal/ModalBus";
import {getTidFromUrl, readT3Table} from "./utils";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useWindowDimensions(): { width: number, height: number } {

    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : 0;
        const height = hasWindow ? window.innerHeight : 0;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
}

export function useOnMountUnsafe(effect: EffectCallback) {
    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            effect()
        }
    }, [])
}

export function useT3Check() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate(); // <-- get history from hook

    const newTournamentWithPlayers = (players: []) => {
        dispatch(clearTournament())
        dispatch(setPlayers(players));
        dispatch(setTournamentOption({maxRounds: getRoundMax(players)}));
        navigate("/")
    }

    useModalEventBus(useT3Check, newTournamentWithPlayers);

    useOnMountUnsafe(function () {
        let tid = getTidFromUrl();
        if (tid !== null) {
            readT3Table(tid as string).then(function (players) {
                if (!players || players.length === 0) return;
                dispatch(openModal({
                                       modalText: t("areYouSureNewTournamentWithPlayerList", {
                                           playersCount: players.length,
                                           providerName: "Tabletop"
                                       }),
                                       buttonActions: [{
                                           label: t("yes"),
                                           closeOnClick: true,
                                           args: players,
                                           targetComponent: useT3Check.name,
                                           method: newTournamentWithPlayers.name,
                                       }, {
                                           label: t("no"),
                                           closeOnClick: true
                                       }],
                                   }))
            });
        }
    });
}