import {useEffect} from 'react'
import EventEmitter from 'events'

export const eventModalBus = new EventEmitter()

const useModalEventBus = (targetComponent: any, handler: (args: any) => void) => {
    useEffect(() => {
        eventModalBus.on(targetComponent.name + "_" + handler.name, handler)
        return () => {
            eventModalBus.off(targetComponent.name + "_" + handler.name, handler)
        }
    }, [targetComponent, handler])
}

export default useModalEventBus


/* Modal example
 dispatch(openModal({
                    id: uuidv4(),
                    buttonActions: [{
                        label: "accept",
                        targetComponent: MatchPage.name,
                        method: acceptMatch.name,
                        args: {toto: 1}
                    }],
                    modalText: "accept match with " + p.id
                }));
 */